(function () {
  'use strict';
  const templateUrl = directiveName => `modules/reports/report-builder/filters/${directiveName}/${directiveName}.template.html`; 

  angular.module('app.modules.report-builder-filters-menu')
  .directive('rbfBrand', function() {
    return {
      restrict: 'E',
      templateUrl: templateUrl('brand'),
      scope: {
        filterItem: '=',
        suggestBrands: '='
      },
      bindToController: true,
      controllerAs: 'vm',
      controller: BrandCtrl
    };
  });

  function BrandCtrl() {
    const vm = this;
    vm.selectedBrands = [];
    vm.currentBrand;

    vm.addBrand = brand => {
      vm.currentBrand = "";
      vm.selectedBrands.push(brand);
    }

    vm.removeBrand = target => {
      vm.selectedBrands = vm.selectedBrands.filter((brand) => brand._id != target)
    }
    
    vm.validateValues = ()=>{
      return vm.selectedBrands.length > 0
    }

    vm.deselectFilterItem = ()=>{
      vm.filterItem.selected = false
    }

    vm.filterItem.getFilterValues = ()=>{
      if(vm.validateValues()){
        return vm.selectedBrands.map((brand) => brand._id);
      }
      return null;      
    }
  };
}());
