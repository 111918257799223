(function () {
  'use strict';

  const moduleName = 'reports.report-builder';
  const moduleFullName = `app.modules.${moduleName}`;

  const state = (stateName) => `app.${moduleName}` + (stateName ? `.${stateName}` : '');
  const controller = (controllerName) => `${moduleFullName}.${controllerName} as vm`;

  angular
    .module(moduleFullName, ['app.modules.report-builder-filters-menu'])
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          url: '/builder',
          abstract: true,
          template: `<div class="modules_reports_report-builder" ui-view></div>`,
        })
        .state(state('index'), {
          url: '',
          controller: controller('ReportBuilderCtrl'),
          templateUrl: 'modules/reports/report-builder/reports.report-builder.html',
          resolve: {
            taxonomy: (taxonomyPromise) => taxonomyPromise,
          },
        });

      angular.injector(['ng']).get('$log').debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
})();
