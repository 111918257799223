'use strict';

// New CPG Item Controller
angular.module('app.controllers')
  .controller('SaveItemCtrl',
    function SaveItemCtrl($scope, $http, $state, navigationGuard,
                          $notification, confirm, $q, debounce,
                          nixTrackApiClient, $filter, generateThumb, $upload, $modal, regions, suggest, taxonomy) {
      let vm = $scope.vm = this;

      vm.upcErrors = {checksum: false};

      vm.suggest  = suggest;
      vm.taxonomy = taxonomy;

      vm.showSugarAlcohols = false;
      vm.sugarAlcohols     = [
        {attribute: 'nf_sugar_alcohol', label: 'Sugar Alcohol'},
        {attribute: 'nf_erythritol', label: 'Erythritol'},
        {attribute: 'nf_allulose', label: 'Allulose'},
        {attribute: 'nf_glycerin', label: 'Glycerin'},
        {attribute: 'nf_xylitol', label: 'Xylitol'},
        {attribute: 'nf_sorbitol', label: 'Sorbitol'},
        {attribute: 'nf_mannitol', label: 'Mannitol'},
        {attribute: 'nf_maltitol', label: 'Maltitol'},
        {attribute: 'nf_isomalt', label: 'Isomalt'},
        {attribute: 'nf_lactitol', label: 'Lactitol'},
      ];

      $scope.isEmptyObject = function (o) {
        return _.keys(o).length === 0;
      };
      vm.uoms              = $scope.uoms = ['g', 'ml'];
      $scope.regions       = regions;

      async function loadItemTags() {
        const item = $scope.item;
        if (item && item._id && item.item_type === 2 /*grocery items*/) {
          try {
            const {data: tags} = await $http.get(`/api/items/${item._id}/tags`)

            const tag = tags[0];
            if (tag) {
              tag.$assigned          = true;
              $scope.item.tag        = tag;
              $scope.tagManager.text = tag.name;
            }
          } catch (e) {
            $notification.error('Item tags could not be loaded: ' + $filter('readError')(e.data));
          }
        }
      }

      async function loadItemCxhHistory() {
        const item = $scope.item;
        if (item && item._id && item.item_type === 2 /*grocery items*/) {
          try {
            const {data} = await $http.get(`/api/cxh-to-nutritionix/history/${item.upc}`);

            vm.cxhToNutritionixHistory = data;
          } catch (e) {
            $notification.error('Item CXH history could not be loaded: ' + $filter('readError')(e.data));
          }
        }
      }

      vm.natural = {
        query:                '',
        applyFdaRound:        true,
        roundMap:             {
          nf_calories:            'calories',
          nf_total_fat:           'total_fat',
          nf_saturated_fat:       'saturated_fat',
          nf_polyunsaturated_fat: 'polyunsaturated_fat',
          nf_monounsaturated_fat: 'monounsaturated_fat',
          nf_trans_fatty_acid:    'trans_fat',
          nf_cholesterol:         'cholesterol',
          nf_sodium:              'sodium',
          nf_total_carbohydrate:  'total_carb',
          nf_dietary_fiber:       'fibers',
          nf_sugars:              'sugars',
          nf_added_sugars:        'added_sugars',
          nf_protein:             'protein',
          nf_calcium_mg:          'calcium',
          nf_potassium:           'potassium',
          nf_vitamin_d_mcg:       'vitamin_d',
          nf_iron_mg:             'iron',
        },
        populateNutrients:    function () {
          if (!this.query) { return; }

          nixTrackApiClient.natural.nutrients(this.query)
            .success(response => {
              let food = response.foods[0];

              food.nf_trans_fatty_acid    = $filter('nutrient')(food.full_nutrients, 605, 'value');
              food.nf_polyunsaturated_fat = $filter('nutrient')(food.full_nutrients, 646, 'value');
              food.nf_monounsaturated_fat = $filter('nutrient')(food.full_nutrients, 645, 'value');
              food.nf_vitamin_d_mcg       = _.round($filter('nutrient')(food.full_nutrients, 324, 'value') / 40, 2); //IU -> mcg
              food.nf_calcium_mg          = $filter('nutrient')(food.full_nutrients, 301, 'value');
              food.nf_iron_mg             = $filter('nutrient')(food.full_nutrients, 303, 'value');
              food.nf_added_sugars        = $filter('nutrient')(food.full_nutrients, 539, 'value');

              food.nf_serving_size_qty  = food.serving_qty;
              food.nf_serving_size_unit = food.serving_unit;

              food.nf_serving_weight       = food.serving_weight_grams;
              food.nf_serving_weight_grams = food.serving_weight_grams;
              food.nf_serving_weight_uom   = 'g';

              if (this.applyFdaRound) {
                for (const attribute of Object.keys(this.roundMap)) {
                  const roundRule = this.roundMap[attribute];

                  if (food[attribute]) {
                    food[attribute] = $filter('fdaRound')(food[attribute], roundRule);
                  }
                }
              }

              if (food.nf_calcium_mg !== null) {
                vm.valueSync.syncDailyValue('calcium', food);
              }
              if (food.nf_iron_mg !== null) {
                vm.valueSync.syncDailyValue('iron', food);
              }

              _.forEach(food, (value, key) => {
                if (value !== null && $scope.item.hasOwnProperty(key)) {
                  $scope.item[key] = value;
                }
              });

              $scope.item.item_name = $scope.item.item_name || $filter('ucwords')(food.food_name);

              $scope.item._uk_energy      = _.round($scope.item.nf_calories * 4.1868);
              $scope.item._uk_salt_source = 'sodium';
              $scope.item._uk_salt_value  = $scope.item.nf_sodium;
              $scope.item._uk_salt_unit   = 'mg';

              $notification.info('Nutrient data has been populated from natural query');
            })
            .error(error => {
              $notification.error('Natural query failed: ' + JSON.stringify(error));
            });
        },
        suggestCommonPhrases: suggest.common_foods
      };

      navigationGuard.watch(function () {
        return $scope.itemForm && $scope.itemForm.$pristine;
      }, 'Unsaved work here. Are you sure you want to leave the page?');

      $scope.brand = {
        id:   null,
        name: null
      };

      (async () => {
        if ($state.params.id) {
          try {
            const {data: item} = await $http.get(`/api/items/${$state.params.id}`);

            vm.item = $scope.item = item;

            $scope.brand.name = item.brand_name;
            $scope.brand.id   = item.brand_id;

            if (!item.region) {
              item.region = 1;
            }

            item._uk_energy      = _.round(item.nf_calories * 4.1868);
            item._uk_salt_source = 'sodium';
            item._uk_salt_value  = item.nf_sodium;
            item._uk_salt_unit   = 'mg';

            await loadItemTags();
            await loadItemCxhHistory();

            if (item.taxonomy_node_id) {
              vm.taxonomyName = _.get(_.find(taxonomy, {node_id: item.taxonomy_node_id}), 'full_tree_text');
            }

            if (item.nf_serving_weight_uom && !vm.uoms.includes(item.nf_serving_weight_uom)) {
              vm.uoms.push(item.nf_serving_weight_uom);
            }
          } catch (e) {
            $notification.error(`Item could not be loaded: ${$filter('readError')(e.data)}`);
          }
        } else {
          vm.item = $scope.item = {
            upc:                       $state.params.upc || null,
            nf_servings_per_container: null,
            region:                    1,
            item_name:                 null,
            item_type:                 2,
            nf_calories:               0,
            _uk_energy:                0,
            _uk_salt_value:            0,
            nf_total_fat:              null,
            nf_calories_from_fat:      null,
            nf_saturated_fat:          null,
            nf_polyunsaturated_fat:    null,
            nf_monounsaturated_fat:    null,
            nf_trans_fatty_acid:       null,
            nf_cholesterol:            null,
            nf_sodium:                 null,
            nf_total_carbohydrate:     null,
            nf_dietary_fiber:          null,
            nf_sugars:                 null,
            nf_added_sugars:           null,
            nf_protein:                null,
            nf_serving_weight_uom:     'g',
            nf_vitamin_d_mcg:          null,
            nf_calcium_dv:             null,
            nf_calcium_mg:             null,
            nf_iron_dv:                null,
            nf_iron_mg:                null,
            nf_potassium:              null,
            synced_from_hive:          1,
            nf_serving_size_qty:       null,
            nf_serving_size_unit:      null,
            nf_serving_weight:         null,
            taxonomy_node_id:          null,
          };

          for (const sugarAlcohol of vm.sugarAlcohols) {
            vm.item[sugarAlcohol.attribute] = null;
          }

          if (vm.item.upc) {
            (function () {
              const itemFormUnwatch = $scope.$watch('itemForm', function itemFormWatch() {
                itemFormUnwatch();
                $scope.itemForm.upc.$setDirty();
              });
            }());

            const {data: meta} = await $http.get(`/api/items/load-meta/${$scope.item.upc}`);
            vm.item.item_name  = $scope.item.item_name || meta.item_name;
            $scope.brand.id    = meta.brand_id;
            $scope.brand.name  = meta.brand_name;
          }
        }
      })();

      vm.setTaxonomy = ($item = null) => {
        vm.taxonomyName              = $item ? $item.full_tree_text : '';
        $scope.item.taxonomy_node_id = $item ? $item.node_id : null;
      }

      vm.fop = {
        thumbnail:         null,
        file:              null,
        croppedImage:      null,
        bounds:            {},
        url:               null,
        generateThumbnail: function () {
          if (this.file) {
            generateThumb(this.file[0])
              .then(thumbnail => {this.thumbnail = thumbnail});
          }
        },
        clearUpload:       function () {
          this.file         = null;
          this.thumbnail    = null;
          this.croppedImage = null;
          this.bounds       = {};
        },
        loadByUrl:         function () {
          if (!this.url) {
            return;
          }

          this.clearUpload();

          $http.get('/cors-proxy?url=' + this.url, {
            responseType:      'blob',
            transformResponse: function (data, headers) {
              return new Blob([data], {type: headers('content-type')});
            }
          })
            .then(response => {
              this.file = [response.data];
              this.generateThumbnail();
            })
            .catch(response => $notification.error('Image could not been loaded'))
            .finally(() => this.url = null)
        }
      };

      // wait while cropper will be set in data object
      $scope.$watch(function () {
        return angular.element('canvas').data('crop.angular-img-cropper');
      }, function (cropper) {
        $scope.cropper = cropper;
      });

      // watch cropper srcImage (and its change) to reset bounds.
      $scope.$watch(function () {
        return $scope.cropper && $scope.cropper.srcImage;
      }, function (initialized) {
        let bounds, cropper;
        if (initialized) {
          $scope.showCanvas = true;
          cropper           = $scope.cropper;
          bounds            = angular.copy(cropper.getBounds());

          bounds.left   = cropper.minXClamp;
          bounds.bottom = cropper.minYClamp;
          bounds.right  = cropper.maxXClamp;
          bounds.top    = cropper.maxYClamp;

          cropper.setBounds(bounds);
          cropper.draw(cropper.ctx);
          vm.fop.bounds = cropper.getCropBounds();
        }
      });

      $scope.saveItem = function () {
        var submitItem;

        _.forEach($scope.item, function (value, key, item) {
          if (value === '') {
            item[key] = null;
          }
        });

        ['nf_vitamin_a_dv', 'nf_vitamin_c_dv', 'nf_vitamin_d_dv'].forEach((deprecatedAttribute) => {
          $scope.item[deprecatedAttribute] = null;
        });

        $scope.item.nf_calories_from_fat = !$scope.nf_total_fat ? 0 : $scope.item.nf_total_fat * 9;


        if ($scope.itemForm.$valid) {
          $scope.item.brand_name = $scope.brand.name;
          $scope.item.brand_id   = $scope.brand.id;

          submitItem = _.pick(
            $scope.item,
            [
              'upc', 'brand_name', 'brand_id',
              'nf_calories_from_fat', 'item_name', 'nf_serving_size_qty',
              'nf_serving_size_unit',
              'nf_serving_weight', 'nf_serving_weight_grams', 'nf_serving_weight_uom',
              'nf_servings_per_container', 'nf_calories',
              'nf_total_fat', 'nf_saturated_fat', 'nf_cholesterol',
              'nf_sodium', 'nf_total_carbohydrate', 'nf_dietary_fiber',
              'nf_sugars', 'nf_protein', 'nf_vitamin_a_dv', 'nf_vitamin_c_dv',
              'nf_calcium_dv', 'nf_iron_dv', 'nf_ingredient_statement', 'item_type', 'region',
              'nf_vitamin_d_dv', 'nf_vitamin_d_mcg', 'nf_trans_fatty_acid', 'nf_calcium_mg',
              'nf_iron_mg', 'nf_potassium', 'nf_added_sugars',
              'nf_polyunsaturated_fat', 'nf_monounsaturated_fat', 'taxonomy_node_id',
              ...vm.sugarAlcohols.map(d => d.attribute),
            ]
          );

          if ($scope.item.region === 2) {
            submitItem.nf_servings_per_container = null;
            submitItem.nf_vitamin_a_dv           = null;
            submitItem.nf_vitamin_c_dv           = null;
            submitItem.nf_calcium_dv             = null;
            submitItem.nf_iron_dv                = null;

            if ($scope.item._uk_energy) {
              submitItem.nf_calories = $scope.item._uk_energy / 4.1868;
            }

            submitItem.nf_sodium = $scope.item._uk_salt_value || 0;
            if ($scope.item._uk_salt_source === 'salt') {
              submitItem.nf_sodium /= 2.5;
            }
            if ($scope.item._uk_salt_unit === 'g') {
              submitItem.nf_sodium *= 1000;
            }
          }

          if (!submitItem.nf_serving_weight) {
            submitItem.nf_serving_weight       = null;
            submitItem.nf_serving_weight_grams = null;
          } else if (submitItem.nf_serving_weight_uom === "g") {
            submitItem.nf_serving_weight_grams = submitItem.nf_serving_weight;
          }

          if (!submitItem.taxonomy_node_id) {
            submitItem.taxonomy_node_id = null;
          }

          let flow;
          let url = '/api/items' + ($scope.item._id ? '/' + $scope.item._id : '');

          if (vm.fop.file) {
            flow = $upload
              .upload({
                url,
                file:             vm.fop.file,
                fileFormDataName: ['fop'],
                fields:           {
                  item:   submitItem,
                  bounds: vm.fop.bounds,
                }
              })
          } else {
            flow = $http.post(url, {
              item:   submitItem,
              tag_id: $scope.item.tag && !$scope.item.tag.$assigned && $scope.item.tag.id
            });
          }

          flow.then(function (response) {
            let savedItem = response.data;

            _.extend($scope.item, savedItem);

            $scope.itemForm.$setPristine();
            vm.fop.clearUpload();

            if ($state.current.name === 'app.items.edit') {
              $notification.info('Item updated');
            } else {
              $notification.info('Item created');

              $state.transitionTo(
                $state.current,
                angular.copy($state.params),
                {reload: true}
              );

              $state.go('app.items.edit', {id: savedItem._id});
            }
          }).catch(function (error) {
            if (error.status === 409) {
              $notification.error('Conflict: Item exists');
            } else {
              $notification.error('Item saving failed: ' + $filter('readError')(error.data));
            }
          });

        } else {
          $notification.error('invalid form');
          $scope.itemForm.submitted = true;
        }
      };

      vm.deleteItem = function deleteItem() {
        const brandName = vm.item.brand_name || 'no brand';
        const itemName  = vm.item.item_name || 'no name';
        const scanCount = vm.item.upc_scan_count || 0;

        const confirmMessage = `Are you sure you want to delete [${brandName} - ${itemName}] with ${scanCount} scans?`;

        confirm(confirmMessage).then(async () => {
          try {
            await $http.delete(`/api/items/${vm.item._id}`);
            $notification.info('Item deleted');
            $state.go('app.items.list');
          } catch (e) {
            $notification.error(`Item deletion failed: ${$filter('readError')(e.data)}`);
          }
        });
      };

      $scope.tagManager = {
        text:   '',
        select: function (tag) {
          $scope.item.tag = tag;
        },
        save:   function () {
          return $scope.item.tag ? this.add() : this.remove();
        },
        clear:  function () {
          $scope.item.tag        = '';
          $scope.tagManager.text = null;
        },
        add:    function () {
          var tag = $scope.item.tag;
          if (!tag) { return; }

          $http.post('/api/items/tags',
            {
              tagId: tag.id,
              items: [$scope.item._id]
            })
            .then(function () {
              $notification.info('Tag ' + tag.name + ' added');
              tag.$assigned = true;
            })
            .catch(function () {
              $notification.error('Tag assign failed');
            });
        },
        remove: function () {
          var tag = $scope.item.tag;

          if (tag && tag.$assigned) {
            confirm('Are you sure you want to unassign tag [' + tag.name + ']').then(function () {
              $http.delete('/api/items/' + $state.params.id + '/tags/' + tag.id)
                .success(function (affected) {
                  if (affected !== 1) {
                    $notification.error('Incorrect affected relations: ' + affected);
                  } else {
                    $notification.info('Tag unassigned');
                    $scope.tagManager.clear();
                  }
                  loadItemTags();
                }).error(function (/* error */) {
                $notification.error('Tag unassignment failed');
              });
            });

          } else {
            $scope.tagManager.clear();
          }
        }
      };

      vm.valueSync = {
        nutrients: {
          calcium: {
            daily_value: 1300,
            attributes:  {
              absolute:    'nf_calcium_mg',
              daily_value: 'nf_calcium_dv'
            }
          },
          iron:    {
            daily_value: 18,
            attributes:  {
              absolute:    'nf_iron_mg',
              daily_value: 'nf_iron_dv'
            }
          }
        },

        syncDailyValue:    function (nutrient, target = null) {
          const definition = this.nutrients[nutrient];
          target           = target || $scope.item;

          if (target[definition.attributes.absolute] === 0 || target[definition.attributes.absolute] > 0) {
            target[definition.attributes.daily_value] = _.round(100 / definition.daily_value * target[definition.attributes.absolute], 4)
          } else if (target[definition.attributes.absolute] === null) {
            target[definition.attributes.daily_value] = null;
          }
        },
        syncAbsoluteValue: function (nutrient, target = null) {
          const definition = this.nutrients[nutrient];
          target           = target || $scope.item;

          if (target[definition.attributes.daily_value] === 0 || target[definition.attributes.daily_value] > 0) {
            target[definition.attributes.absolute] = _.round(target[definition.attributes.daily_value] / 100 * definition.daily_value, 4);
          } else if (target[definition.attributes.daily_value] === null) {
            target[definition.attributes.absolute] = null;
          }
        }
      }

      vm.verify = () => {
        const modalInstance = $modal.open({
          size: 'md',
          animation: false,
          templateUrl: 'views/admin/items/verify.modal.html',
          controller: function ($scope, $modalInstance, item) {
            const vm = ($scope.vm = this);
            vm.item = item;

            vm.selection = '';

            vm.save = async () => {
              vm.save.$busy = true;
              try {
                const updates = {};
                if (vm.selection === 'nutrition') {
                  updates.nutrition = true;
                } else if (vm.selection === 'ingredients') {
                  updates.ingredients = true;
                } else if (vm.selection === 'both') {
                  updates.nutrition = true;
                  updates.ingredients = true;
                }

                if (Object.keys(updates).length > 0) {
                  const response = await $http.post(`/api/items/${vm.item._id}/verify`, updates);
                  vm.item.nutrition_verified_at = response.data.nutrition_verified_at;
                  vm.item.ingredient_statement_verified_at = response.data.ingredient_statement_verified_at;
                  $notification.success('Verification dates updated');
                } else {
                  $notification.info('No changes made');
                }
                $modalInstance.close();
              } catch (error) {
                $notification.error($filter('readError')(error.data));
              } finally {
                vm.save.$busy = false;
              }
            };

            vm.close = () => {
              $modalInstance.dismiss();
            };
          },
          resolve: {
            item: () => $scope.item,
          },
        });
      };
    }
  )
  .directive('validateBrand', [function () {
    return {
      scope:   {brand: '=validateBrand'},
      require: 'ngModel',
      link:    function (scope, elm, attrs, ngModel) {
        scope.$watchCollection('brand', () => ngModel.$setValidity('required', !!scope.brand.id));
      }
    };
  }]);
