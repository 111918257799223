(function () {
  'use strict';

  const templateUrl = directiveName => `modules/reports/report-builder/filters/${directiveName}/${directiveName}.template.html`; 

  angular.module('app.modules.report-builder-filters-menu')
  .directive('rbfUniversalProductCodeDigitCount', function() {
    return {
      restrict: 'E',
      templateUrl: templateUrl('universal-product-code-digit-count'),
      scope: {
        filterItem: '='
      },
      bindToController: true,
      controllerAs: 'vm',
      controller: UniversalProductCodeDigitCountCtrl
    };
  });

  function UniversalProductCodeDigitCountCtrl() {
    const vm = this;    
    vm.slider = {
      type:'range',
      minValue:6,
      maxValue:16,
      options: {
        floor: 1,
        ceil: 16,
        step: 1
      }
    }
    
    vm.validateValues = ()=>{
      return true
    }

    vm.deselectFilterItem = ()=>{
      vm.filterItem.selected = false
    }

    vm.filterItem.getFilterValues = ()=>{
      if(vm.validateValues()){
        return (vm.slider.type === 'null') ? {type:vm.slider.type}: {type:vm.slider.type, minValue:vm.slider.minValue, maxValue:vm.slider.maxValue};
      }
      return null;
    }
  };
}());
