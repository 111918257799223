(function () {
  'use strict';

  const templateUrl = directiveName => `modules/reports/report-builder/filters/${directiveName}/${directiveName}.template.html`; 

  angular.module('app.modules.report-builder-filters-menu')
  .directive('rbfDateRange', function() {
    return {
      restrict: 'E',
      templateUrl: templateUrl('date-range'),
      scope: {
        filterItem: '=',
        label:'='
      },
      bindToController: true,
      controllerAs: 'vm',
      controller: DateRangeCtrl
    };
  });

  /*@ngInject*/
  function DateRangeCtrl($notification, moment) {
    const vm = this;
    vm.dateFormat = 'MM/dd/yyyy';
    vm.startDate = {
      type:'START',
      opened:false,
      value:null,
      valid:null
    }

    vm.endDate = {
      type:'END',
      opened:false,
      value:null,
      valid:null
    }

    vm.formatDateMySQL = (date) => {
      if(date === null) return null;
      return moment(date).format('YYYY-MM-DD');
    };

    vm.open = (dateValueOptions) =>{
      dateValueOptions.opened = true;
    }

    vm.validateDateValue = (dateValueOptions) => {
      dateValueOptions.valid = dateValueOptions.value === null || dateValueOptions.value instanceof Date;
      if(vm.startDate.value instanceof Date &&  vm.endDate.value instanceof Date){

        if(moment(vm.startDate.value).isSame(vm.endDate.value)) return;

        if(dateValueOptions.type === 'START'){
          if(!moment(vm.endDate.value).isAfter(dateValueOptions.value)){
            $notification.error('Start Date has to be on or before End Date');
            vm.startDate.value = null
          }
              
        } else if(dateValueOptions.type === 'END'){
          if(!moment(dateValueOptions.value).isAfter(vm.startDate.value)){
            $notification.error('End Date has to be on or after Start Date');
            vm.endDate.value = null
          }
        } 
      }                    
    }
    
    vm.validateValues = ()=>{
      vm.validateDateValue(vm.startDate) 
      vm.validateDateValue(vm.endDate)
      if(vm.startDate.value === null && vm.endDate.value === null) return false;
      return vm.startDate.value instanceof Date || vm.endDate.value instanceof Date;
    }

    vm.deselectFilterItem = ()=>{
      vm.filterItem.selected = false
    }

    vm.filterItem.getFilterValues = ()=>{
      if(vm.validateValues()){
        return {startDate:vm.formatDateMySQL(vm.startDate.value), 
          endDate: vm.formatDateMySQL(vm.endDate.value)};
      }
      return null;
    }
  };
}());
