(function () {
  'use strict';
  const templateUrl = directiveName => `modules/reports/report-builder/filters/${directiveName}/${directiveName}.template.html`; 

  angular.module('app.modules.report-builder-filters-menu')
  .directive('rbfIngredientsCoverage', function() {
    return {
      restrict: 'E',
      templateUrl: templateUrl('ingredients-coverage'),
      scope: {
        filterItem: '='
      },
      bindToController: true,
      controllerAs: 'vm',
      controller: IngredientsCoverageCtrl
    };
  });
  
  function IngredientsCoverageCtrl() {
    const vm = this;
    vm.IngredientsCoverage = 'HAS_INGREDIENT_STATEMENT';
    
    vm.deselectFilterItem = ()=>{
      vm.filterItem.selected = false
    }

    vm.filterItem.getFilterValues = ()=>{
      return vm.IngredientsCoverage;
    }
  };
}());
