(function () {
  'use strict';

  const templateUrl = directiveName => `modules/reports/report-builder/filters/${directiveName}/${directiveName}.template.html`; 

  angular.module('app.modules.report-builder-filters-menu')
  .directive('rbfTaxonomyNode', function() {
    return {
      restrict: 'E',
      templateUrl: templateUrl('taxonomy-node'),
      scope: {
        filterItem: '=',
        taxonomy: '='
      },
      bindToController: true,
      controllerAs: 'vm',
      controller: TaxonomyNodeCtrl
    };
  });

  function TaxonomyNodeCtrl() {
    const vm = this;
    vm.taxonomyNodes = [{full_tree_text:'No Taxonomy',leaf_node_name:'No Taxonomy',node_id:null}];
    vm.taxonomyNodes = vm.taxonomyNodes.concat(vm.taxonomy);
    vm.noSelected = false;
    vm.selectedTaxonomyNodes = [];

    vm.addNodeToSelectedList = (nodeItem = null) => {
      if(nodeItem.full_tree_text === 'No Taxonomy'){
        vm.noSelected = true;
        vm.selectedTaxonomyNodes = [];
        vm.selectedTaxonomyNodes.push(nodeItem)
      } else {
        if(!vm.selectedTaxonomyNodes.some(node => node.full_tree_text === nodeItem.full_tree_text)){
          vm.selectedTaxonomyNodes.push(nodeItem)
        }
      }
      vm.taxonomyName  = ''; 
    }

    vm.removeTaxonomyNode = (nodeItem = null) => {
      if(nodeItem.full_tree_text === 'No Taxonomy'){
        vm.noSelected = false;
      } 
      vm.selectedTaxonomyNodes = vm.selectedTaxonomyNodes.filter((node) => node.full_tree_text != nodeItem.full_tree_text)
    }

    vm.validateValues = ()=>{
      return vm.selectedTaxonomyNodes.length > 0
    }

    vm.deselectFilterItem = ()=>{
      vm.filterItem.selected = false
    }

    vm.filterItem.getFilterValues = ()=>{
      if(vm.validateValues()){
        return vm.selectedTaxonomyNodes.map((node) => node.node_id);
      }
      return null;
    }
  };
}());
