(function () {
  'use strict';

  const moduleName = 'ingredient-learning';

  const moduleFullName = `app.modules.${moduleName}`;

  const state       = stateName => `app.${moduleName}` + (stateName ? `.${stateName}` : '');
  const templateUrl = viewName => `modules/${moduleName}/${moduleName}.${viewName}.html`;
  const controller  = controllerName => `${moduleFullName}.${controllerName} as vm`;

  angular.module(moduleFullName, ['app.modules.ingredient-learning.dual-entry'])
    .constant('ingredientFlagReasons', {
      types:    [
        {value: 'duplicate', label: 'duplicate'},
        {value: 'invalid', label: 'invalid'},
        {value: 'needs_rd_review', label: 'needs RD review'}
      ],
      getLabel: function (value) {
        return _.get(_.find(this.types, {value}), 'label');
      }
    })
    .factory('sampleItemsModal', function ($modal, $http, $filter, $notification) {
      return function (ingredient) {
        return $modal.open({
          size:        'lg',
          animation:   false,
          templateUrl: templateUrl('sample-items.modal'),
          controller:  function ($scope, $modalInstance, ingredient, items) {
            const vm = $scope.vm = this;

            vm.ingredient = ingredient;
            vm.items      = items;

            vm.close = () => {
              $modalInstance.dismiss();
            }
          },
          resolve:     {
            ingredient: () => ingredient,
            items:      () => $http.get('/api/ingredient-learning/sample-items', {params: {q: ingredient.name}})
              .then(response => response.data)
              .catch(response => {
                $notification.error('Failed to load sample products: ' + $filter('readError')(response.body));
                return [];
              })
          }
        });
      }
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state(state(), {
          url:      `/${moduleName}`,
          abstract: true,
          template: `<div class="module_${moduleName}" ui-view></div>`,
          data:     {
            permissions: ['ingredient-learning']
          },
          resolve: {
            utils:    () => ({state, controller, templateUrl}),
            grouping: ($http, $q, $filter, $notification) => {
              return $http.get('/static/ingredient-groupings.json')
                .then(response => {
                  const grouping = response.data;
                  grouping.get   = id => id && _.find(grouping, {id});
                  return grouping;
                })
                .catch(response => {
                  $notification.error('Failed to load grouping data: ' + $filter('readError')(response.data));
                  return $q.reject(response);
                })
            },
            questions: ($http, $q, $filter, $notification) => {
              return $http.get('/api/ingredient-learning/questions')
                .then(response => response.data)
                .catch(response => {
                  $notification.error('Failed to load questions: ' + $filter('readError')(response.data));
                  return $q.reject(response);
                })
            },
            additionalQuestions: ($http, $q, $filter, $notification) => {
              return $http.get('/api/ingredient-learning/questions-additional')
                .then(response => response.data)
                .catch(response => {
                  $notification.error('Failed to load additional questions: ' + $filter('readError')(response.data));
                  return $q.reject(response);
                })
            }
          }
        })
        .state(state('list-ingredients'), {
          url:         '/ingredients',
          controller:  controller('ListIngredientsCtrl'),
          templateUrl: templateUrl('list-ingredients')
        })
        .state(state('edit-ingredient'), {
          url:         '/ingredients/:id',
          controller:  controller('EditIngredientCtrl'),
          templateUrl: templateUrl('edit-ingredient'),
          resolve:     {
            ingredient: ($http, $stateParams, $notification, $q, $filter, $state, $sessionStorage, $location) => {
              return $http.get(`/api/ingredient-learning/ingredients/${$stateParams.id}`)
                .then(response => response.data)
                .catch(response => {
                  $notification.error('Failed to load ingredient: ' + $filter('readError')(response.data));

                  if ($sessionStorage.ingredientLearningListState) {
                    $location.url($sessionStorage.ingredientLearningListState);
                  } else {
                    $state.go('app.ingredient-learning.list-ingredients');
                  }

                  return $q.reject(response);
                })
            }
          }
        })
        .state(state('guessed-phrases'), {
          url:         '/guessed-phrases',
          controller:  controller('ListGuessedPhrasesCtrl'),
          templateUrl: templateUrl('list-guessed-phrases')
        })
        .state(state('corrections-needed-phrases'), {
          url:         '/corrections-needed-phrases',
          controller:  controller('ListCorrectionsNeededPhrasesCtrl'),
          templateUrl: templateUrl('list-corrections-needed-phrases')
        })
      ;

      angular.injector(['ng']).get('$log')
        .debug(`[${moduleName}] Module configured`);
    })
    .run(function ($log) {
      $log.debug(`[${moduleName}] Module launched`);
    });
}());


