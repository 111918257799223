(function () {
  'use strict';

  const moduleName = 'report-builder-filters-menu';  
  const moduleFullName = `app.modules.${moduleName}`;
  const templateUrl = directiveName => `modules/reports/report-builder/${directiveName}.template.html`; 

  angular.module(moduleFullName, [])
  .directive('reportBuilderFiltersMenu', function() {
    return {
      restrict: 'E',
      templateUrl: templateUrl('filters-menu'),
      scope: {
        taxonomy:'=',
        suggestBrands:'=',
        getSelectedFilters:'='
      }, 
      bindToController: true,
      controllerAs: 'vm',
      controller: ReportFiltersMenuCtrl
    };
  });

  /*@ngInject*/
  function ReportFiltersMenuCtrl($notification) {
    const vm = this;
    vm.filters = [
      {name:'Nutrient Values',key:'NUTRIENT_VALUES', selected: false, valid:null},
      {name:'Taxonomy', key:'TAXONOMY', selected: false, valid:null},
      {name:'Brand', key:'BRAND', selected: false, value:null, valid:null},
      {name:'Ingredient Statement Coverage', key:'INGREDIENTS_COVERAGE', selected: false, valid:null},
      {name:'Last Updated Date',key:'LAST_UPDATED_DATE', selected: false, valid:null},
      {name:'UPC Digit Count',key:'UPC_DIGIT_COUNT', selected: false, valid:null},
      {name:'Current Final ("Legacy") NFP Asset Created Date',key:'LEGACY_ASSET_CREATED_DATE', selected: false, valid:null},
      {name:'Most Recent S3 NFP Asset Created Date',key:'MRS3NFP_ASSET_CREATED_DATE', selected: false, valid:null}
    ]

    vm.getMenuSelectedFilters = () =>{
      return vm.filters.filter( filter => filter.selected === true)
    }

    vm.resetValidStatus = (filter)=>{
      filter.valid = null
    }

    vm.deselectFilter = (filter)=>{
      filter.selected = false
      filter.valid = null
    }      

    vm.clearAllFilters = ()=>{
      vm.filters.map((filter) => filter.selected = false)
    }

    vm.getSelectedFilters = ()=>{
      let reportFilters = [];
      let invalidFilterExist = false;

      vm.getMenuSelectedFilters().map((filter) =>{
        let filterValues = filter.getFilterValues();

        if(filterValues){
          filter.valid = true;
          reportFilters.push({key:filter.key, value:filterValues})
        } else {
          filter.valid = false;
          invalidFilterExist = true;
        }
      })

      if(invalidFilterExist){
        $notification.error('Please fix highlighted filters');
        return null;
      }
      return reportFilters;
    }
  };
}());
