(function () {
  'use strict';

  const templateUrl = directiveName => `modules/reports/report-builder/filters/${directiveName}/${directiveName}.template.html`; 

  angular.module('app.modules.report-builder-filters-menu')
  .directive('rbfNutrientValues', function() {
    return {
      restrict: 'E',
      templateUrl: templateUrl('nutrient-values'),
      scope: {
        filterItem: '='
      },
      bindToController: true,
      controllerAs: 'vm',
      controller: NutrientValuesCtrl
    };
  });

  /*@ngInject*/
  function NutrientValuesCtrl($notification) {
    const vm = this;
    vm.availableNutrientValues = [
      {name:'Calories', field:'nf_calories', selected: false, type:'null',value:null, valid:null},
      {name:'Fat Calories', field:'nf_calories_from_fat', selected: false, type:'null',value:null, valid:null},
      {name:'Total Fat', field:'nf_total_fat', selected: false, type:'null',value:null, valid:null},
      {name:'Saturated Fat', field:'nf_saturated_fat', selected: false, type:'null',value:null, valid:null},
      {name:'Trans Fat', field:'nf_trans_fatty_acid', selected: false, type:'null',value:null, valid:null},
      {name:'Cholesterol', field:'nf_cholesterol', selected: false, type:'null',value:null, valid:null},
      {name:'Sodium', field:'nf_sodium', selected: false, type:'null',value:null, valid:null},
      {name:'Total Carb', field:'nf_total_carbohydrate', selected: false, type:'null',value:null, valid:null},
      {name:'Fiber', field:'nf_dietary_fiber', selected: false, type:'null',value:null, valid:null},
      {name:'Total Sugar', field:'nf_sugars', selected: false, type:'null',value:null, valid:null},
      {name:'Added Sugar', field:'nf_added_sugars', selected: false, type:'null',value:null, valid:null},
      {name:'Protein', field:'nf_protein', selected: false, type:'null',value:null, valid:null},
      {name:'Vitamin D mcg', field:'nf_vitamin_d_mcg', selected: false, type:'null',value:null, valid:null},
      {name:'Calcium mg', field:'nf_calcium_mg', selected: false, type:'null',value:null, valid:null},
      {name:'Potassium mg', field:'nf_potassium_mg', selected: false, type:'null',value:null, valid:null},
      {name:'Iron mg', field:'nf_iron_mg', selected: false, type:'null',value:null, valid:null}
    ]

    vm.toggleSelection = (nutrient, selection)=>{
      if(selection === true && vm.getSelectedNutrients().length === 5){
        $notification.error('Only 5 filters are allowed');
        return;
      }      
      nutrient.type = 'null'
      nutrient.value = null
      nutrient.selected = selection;
    }

    vm.getSelectedNutrients = () =>{
      return vm.availableNutrientValues.filter((nutrient)=> nutrient.selected === true)
    }

    vm.getUnselectedNutrients = ()=> {
      return vm.availableNutrientValues.filter((nutrient)=> nutrient.selected === false)
    }

    vm.limitToFiveCharacters = (nutrient)=>{
      if(nutrient.value.toString().length > 5){
        nutrient.value = parseInt(nutrient.value.toString().slice(0, 5));
      }
    }

    vm.validateValues = ()=>{      
      let valuesAreValid = true; 
      let selectedNutrients = vm.getSelectedNutrients();
      if(selectedNutrients.length == 0){
        valuesAreValid = false;
      }
          
      selectedNutrients.map((item) => {
        if(item.type !== 'null' && (isNaN(item.value)  || item.value === null)){
          item.valid = false;
          valuesAreValid = false;
        } else {
          item.valid = true;
        }
      })
      return valuesAreValid;
    }

    vm.deselectFilterItem = ()=>{
      vm.filterItem.selected = false
    }

    vm.filterItem.getFilterValues = ()=>{
      if(vm.validateValues()){
        return vm.getSelectedNutrients();
      }
      return null;
    }
  };
}());
