'use strict';

angular.module('app.controllers')
    .controller('spotCheckCtrl',
      function ($scope, $http, ngTableParams, $location, $notification, moment) {
            $scope.filter_by = 'priority';

            $scope.search = {
                term:    $location.search().q || '',
                filter: {
                    type: $location.search().type || ''
                },
                perform: function () {
                    $scope.tableParams.page(1);
                    $scope.tableParams.reload();
                }
            };

            $scope.tableParams = new ngTableParams(
                {
                    page:    1,
                    count:   25,
                    sorting: (() => {
                        if ($location.search().sort) {
                            const parts = $location.search().sort.split('.');
                            const sort = {};

                            sort[parts[0]] = parts[1];

                            return sort;
                        }

                        return {name: 'asc'};
                    })()
                },
                {
                    counts:  [],
                    total:   0,
                    getData: async ($defer, params) => {
                      $location.search('q', $scope.search.term || undefined);
                      $location.search('type', $scope.search.filter.type || undefined);

                      const sort = Object.entries(params.sorting())[0].join('.');

                      $location.search('sort', sort !== 'name.asc' ? sort : undefined);

                      try {
                        const response = await $http.get('/api/admin-tools/spot-checks', {
                          params: {
                            limit: params.count(),
                            offset: (params.page() - 1) * params.count(),
                            search: $scope.search.term,
                            filter_by: $scope.filter_by
                          }
                        });
                        response.data.results.forEach(function (item) {
                          if (item.last_spot_checked_at != null) {
                            const lastSpotCheckeDate = moment(item.last_spot_checked_at);
                            item.last_spot_checked_at_str = +moment().diff(lastSpotCheckeDate, 'days') + ' Days Ago';
                          } else {
                            item.last_spot_checked_at_str = 'Never';
                          }
                          if (item.last_updated != null) {
                            const lastCheckeDate = moment(item.last_updated);
                            item.last_updated_str = +moment().diff(lastCheckeDate, 'days') + ' Days Ago';
                          } else {
                            item.last_updated_str = 'Never';
                          }
                          item.status = item.brand_status || 'None';
                        });

                        params.total(response.data.count);
                        $defer.resolve(response.data.results);
                      } catch (error) {
                        $notification.error('Error fetching spot check list', error);
                        throw error;
                      }
                    }
                }
            );

            $scope.applyFilter = function (filter_by) {
              if ($scope.filter_by !== filter_by) {
                $scope.filter_by = filter_by;
                $scope.tableParams.page(1);
                $scope.tableParams.reload();
              }
            }
    })
    .controller('spotCheckFailModalCtrl',
      function ($scope, $modalInstance, submit, brandName) {
        $scope.brandName = brandName;

        $scope.ok = function () {
          $modalInstance.close();
          submit(false);
        };

        $scope.cancel = function () {
          $modalInstance.dismiss('cancel');
        };
      })
    .controller('canNotCompleteModalCtrl',
      function ($scope, $modalInstance, brand, $http, $notification) {
        const { brand_id } = brand;
        $scope.selectedOption = null;
        $scope.incompletionReasons = {
          nutritionDataNotAvailable: 'NUTRITION_DATA_UNAVAILABLE',
          other: 'OTHER',
        };
        $scope.notes = { value: '' };

        $scope.save = async () => {
          const payload = {
            brand_id,
            incompletionReason: $scope.selectedOption,
            notes: $scope.notes.value
          };

          await $http.post(`/api/brands/${brand_id}/events`, payload);
          $notification.success('Spot check incompletion saved successfully.');
          $modalInstance.close();
        };

        $scope.cancel = () => {
          $modalInstance.dismiss('cancel');
        };
      })
    .controller('spotCheckViewCtrl',
      function ($scope, $modal, $http, $state, $notification) {
            /**
             * Modal settings for `Mark as Fail` option
             */
            $scope.open = function (size) {
              var modalInstance = $modal.open({
                animation: false,
                templateUrl: 'myModalContent.html',
                controller: 'spotCheckFailModalCtrl',
                size: size,
                resolve: {
                  submit: function () {
                    return $scope.submit;
                  },
                  brandName: function () {
                    return $scope.task.brand_name;
                  }
                }
              });

              modalInstance.result.then(function (selectedItem) {
                $scope.selected = selectedItem;
              });
            };

            $scope.openModal = function (size) {
              $modal.open({
                animation: false,
                templateUrl: 'canNotCompleteModalContent.html',
                controller: 'canNotCompleteModalCtrl',
                size: size,
                resolve: {
                  brand: function () {
                    return $scope.task;
                  }
                }
              });
            };

            $scope.spot_check_id = $state.params.id;
            $scope.user_name = $scope.auth.profile.name;
            $scope.menu_items_buffer = '';
            $scope.is_submitted = false;

            // Reset nf_calories and nf_sodium when market as unable to locate
            $scope.unableToLocate = function (menuItemIndex) {
              $scope.task.entered_menu_items[menuItemIndex].nf_calories = undefined;
              $scope.task.entered_menu_items[menuItemIndex].nf_sodium = undefined;
            };

            async function populateForm() {
              try {
                const response = await $http.get(`/api/admin-tools/spot-checks/${$scope.spot_check_id}`);
                $scope.task = response.data;
                $scope.task.user_name = $scope.user_name;
                $scope.task.entered_menu_items = JSON.parse($scope.task.entered_menu_items);
                $scope.task.random_menu_items = JSON.parse($scope.task.random_menu_items);
              } catch (error) {
                $notification.error('Error populating spot-check form', error);
              }
            };
            populateForm();

            $scope.updateStep1 = function () {
              if ($scope.task.all_menu_items_exists === 'na') {
                $scope.menu_items_buffer = $scope.task.new_menu_items;
                $scope.task.new_menu_items = '';

                $scope.task.cant_locale_new_menu_items = 1;
              } else {
                if ($scope.menu_items_buffer) {
                  $scope.task.new_menu_items = $scope.menu_items_buffer;
                }
                $scope.task.cant_locale_new_menu_items = 0;
              }
            };

            $scope.updateStep2 = function () {
              if ($scope.task.cant_locale_new_menu_items == 1) {
                $scope.menu_items_buffer = $scope.task.new_menu_items;
                $scope.task.new_menu_items = '';

                $scope.task.all_menu_items_exists = 'na';
              } else {
                $scope.task.new_menu_items = $scope.menu_items_buffer;
                $scope.task.all_menu_items_exists = undefined;
              }
            };

            $scope.submit = async (pass) => {
              if (pass === true) {
                $scope.task.pass = true;
              } else if (pass === false) {
                $scope.task.pass = false;
              }

              if ($scope.task.pass === false || $scope.spotCheckForm.$valid) {
                try {
                  await $http.post(`/api/admin-tools/spot-checks/${$scope.spot_check_id}`, $scope.task);
                  $scope.is_submitted = true;
                } catch (error) {
                  $notification.error(error);
                  throw error;
                }
              }
            };
        });
