(function () {
  'use strict';

  const moduleName     = 'ingredient-learning',
        controllerName = 'ListGuessedPhrasesCtrl';

  const moduleFullName = `app.modules.${moduleName}`;

  angular.module(moduleFullName)
    .controller(`${moduleFullName}.${controllerName}`, ListGuessedPhrasesCtrl);

  /*@ngInject*/
  function ListGuessedPhrasesCtrl($log, $scope, $http, $notification, $filter, ngTableParams,
                                  $location, debounce, $modal, confirm, $state) {
    $log.debug(`[${moduleName}] Controller "${controllerName}" launched`);

    const vm = this;

    const debouncedReload = debounce(function () {
      vm.tableParams.reload();
    }, 1000);

    vm.removeResult = function (item, reloadAfter = Promise.resolve()) {
      _.pull(vm.data, item);
      reloadAfter.then(debouncedReload);
    };

    vm.ignorePhrase = (phrase) => {
      confirm(`Are you sure you want to make this a learned phrase that is flagged to be ignored?`).then(async () => {
        try {
          const response = await $http.put(`/api/ingredient-learning/guessed-phrases/${phrase.id}/ignore`, {
            is_ignored: 1,
            text: phrase.phrase,
          });
          $notification.success(response.data.message);
        } catch (error) {
          $notification.error($filter('readError')(error.data));
        }

        vm.removeResult(phrase);
      });
    };


    vm.errorPhrase = (phrase) => {
      confirm(`Are you sure you want to mark this unlearned phrase as requiring further action by Intake?`).then(async () => {
        try {
          const response = await $http.put(`/api/ingredient-learning/guessed-phrases/${phrase.id}/error`, {
            is_data_error: !phrase.is_data_error,
          });
          $notification.success(response.data.message);
        } catch (error) {
          $notification.error($filter('readError')(error.data));
        }
        vm.removeResult(phrase);
      });
    };

    vm.deletePhrase = async (phrase) => {
      try {
        const response = await $http.delete(`/api/ingredient-learning/guessed-phrases/${phrase.id}`);
        $notification.info(response.data.message);
      } catch (error) {
        $notification.error(`Phrase not deleted: ` + $filter('readError')(error.data));
      }
    };

    vm.createIngredient = (phrase) => {
      const modalInstance = $modal.open({
        size: 'md',
        animation: false,
        templateUrl: 'modules/ingredient-learning/ingredient-learning.list-guessed-phrases.create-phrase-to-ingredient.modal.html',
        controller: function ($scope, $modalInstance) {
          const vm = ($scope.vm = this);

          vm.phrase = phrase;
          vm.correction_needed = false;
          vm.is_typo = false;

          vm.setIngredient = (ingredient) => {
            vm.correction_needed = ingredient.correction_needed;
            vm.is_typo = ingredient.is_typo;
            vm.phrase.tag_id = ingredient.id;
          };

          vm.save = async () => {
            vm.save.$busy = true;
            try {
              await $http.post(`/api/ingredient-learning/guessed-phrases/${phrase.id}`, {
                text: vm.phrase.phrase,
                correction_needed: vm.correction_needed,
                is_typo: vm.is_typo,
              });
              $notification.success('Phrase saved');
              $modalInstance.close(phrase);
            } catch (error) {
              $notification.error($filter('readError')(error.data));
            } finally {
              vm.save.$busy = false;
            }
          };

          vm.close = () => {
            $modalInstance.dismiss();
          };
        },
        resolve: {
          ingredient: () => vm.ingredient,
        },
      });
      modalInstance.result.then(() => {
        vm.removeResult(phrase);
      });
    };


    vm.assignPhraseToIngredient = (phrase) => {
      const modalInstance = $modal.open({
        size: 'md',
        animation: false,
        templateUrl: 'modules/ingredient-learning/ingredient-learning.list-guessed-phrases.assign-phrase-to-ingredient.modal.html',
        controller: function ($scope, $modalInstance, ingredient) {
          const vm = ($scope.vm = this);

          vm.phrase = phrase;
          vm.ingredientName = '';
          vm.correction_needed = false;
          vm.is_typo = false;

          vm.searchIngredients = async (q) => {
            try {
              const response = await $http.get('/api/ingredient-learning/ingredients', { params: { q } });
              return response.data.ingredients;
            } catch (error) {
              return [];
            }
          };

          vm.setIngredient = (ingredient) => {
            vm.ingredientName = ingredient.name;
            vm.correction_needed = ingredient.correction_needed;
            vm.is_typo = ingredient.is_typo;
            vm.phrase.tag_id = ingredient.id;
          };

          vm.save = async () => {
            vm.save.$busy = true;
            try {
              await $http.put(`/api/ingredient-learning/guessed-phrases/${phrase.id}/assign`, {
                text: vm.ingredientName,
                correction_needed: vm.correction_needed,
                is_typo: vm.is_typo,
              });
              $notification.success('Phrase Updated');
              $modalInstance.close(vm.phrase);
            } catch (response) {
              $notification.error($filter('readError')(response.data));
            } finally {
              vm.save.$busy = false;
            }
          };

          vm.close = () => {
            $modalInstance.dismiss();
          };
        },
        resolve: {
          ingredient: () => vm.ingredient,
        },
      });
      modalInstance.result.then(() => {
        vm.removeResult(phrase);
      });
    };


    vm.data = [];

    vm.search = {
      exact:      false,
      term:       $location.search().q || '',
      is_ignored: +$location.search().is_ignored || 0,
      perform:    function (exact = false) {
        this.exact = exact;
        vm.tableParams.page(1);
        vm.tableParams.reload();
      }
    };

    const defaultOrder = 'id.asc';
    $scope.params      = vm.tableParams = new ngTableParams(
      {
        page:    $location.search().page || 1,            // show first page
        count:   25,           // count per page
        sorting: (function () {
          let orderBy = $location.search().orderBy || defaultOrder;
          if (orderBy) {
            orderBy = orderBy.split('.');

            let result         = {};
            result[orderBy[0]] = orderBy[1];

            return result;
          }
        }())
      },
      {
        counts:  [],
        total:   0, // length of data
        getData: function ($defer, params) {
          $location.search('page', params.page() > 1 ? params.page() : null);

          $location.search('q', vm.search.term || null);
          $location.search('is_ignored', vm.search.is_ignored || null);

          let orderBy = null;
          if (_.keys(params.sorting()).length) {
            let [sortingAttribute, sortingDirection] = _.pairs(params.sorting())[0];
            orderBy                                  = `${sortingAttribute}.${sortingDirection}`;
          }

          $location.search('orderBy', orderBy === defaultOrder ? null : orderBy);

          $http.get('/api/ingredient-learning/guessed-phrases', {
              params: {
                limit:      params.count(),
                offset:     (params.page() - 1) * params.count(),
                q:          vm.search.term || undefined,
                is_ignored: vm.search.is_ignored,
                orderBy:    orderBy,
              }
            })
            .then(response => {
              params.total(response.data.total);
              $defer.resolve(vm.data = response.data.phrases);
            })
            .catch(response => {
              $notification.error(
                'Failed to load phrases: ' + $filter('readError')(response.data)
              );

              $defer.reject();
            });
        }
      }
    );
  }
}());
